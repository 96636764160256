import React, { useState, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import * as b from "../components/bootstrap.module.css"
import useConfig from '../hooks/useConfig'
import Thumbnail from "../components/thumbnail";
import Partners from "../components/partners";
import RankingWidget from '../components/ranking-widget'
import Icon from '../components/icon'
import Team from '../components/team'
import Translate from '../components/translate'
import Charger from '../components/charger'
import useMedia from '../hooks/useMedia';
import BlogContent from '../components/blog-content'
import Layout from '../components/layout'
import siteMetadata from '../utils/site-metadata';

import BaseHead from '../components/base-head';
export const Head = ({ ...props }) => (
  <BaseHead {...props} 
    title="Porównywarka kredytów i pożyczek online | akredo.pl" 
    description="Platforma akredo.pl pozwala bezpłatnie sprawdzić koszty kredytów, pożyczek i kont bankowych. Wszystko online i za darmo!"
  >
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
      "@context": "http://schema.org",
      "@type": "AggregateRating",
      "ratingCount": props.data.allMysqlComments.totalCount,
      "reviewCount": props.data.allMysqlComments.totalCount,
      "ratingValue": (props.data.allMysqlComments.nodes.reduce((prev, curr) => prev + curr.rating, 0) / props.data.allMysqlComments.totalCount) % 10
    }) }}></script>
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
      "@context": "http://schema.org",
      "@type":"FinancialService",
      "image": `${siteMetadata.domain}/assets/Logo.svg`,
      "legalName":"Akredo Sp. z o.o",
      "name":"Akredo.pl",
      "url": `${siteMetadata.domain}/`,
      "priceRange":"100-250000",
      "telephone":"+48511574319",
      "brand": {
        "@type":"Organization",
        "name":"Loando Group",
        "url": `${siteMetadata.domain}/`,
        "taxID": "7393937829",
        "additionalProperty": [
          {
            "@type": "PropertyValue",
            "name": "KRS",
            "value": "0000820312",
          },
          {
            "@type": "PropertyValue",
            "name": "REGON",
            "value": "385610711"
          }
        ],
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Kotańskiego 8/1",
        "addressLocality": "Olsztyn",
        "email": "bok@loando.pl",
        "postalCode": "10-166",
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "53.7808713",
        "longitude": "20.4674455"
      },
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": "https://schema.org/Monday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": "https://schema.org/Tuesday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek":  "https://schema.org/Wednesday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes":  "17:00:00",
          "dayOfWeek": "https://schema.org/Thursday",
          "opens": "09:00:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "closes": "17:00:00",
          "dayOfWeek": "https://schema.org/Friday",
          "opens": "09:00:00"
        },
      ],
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingCount": props.data.allMysqlComments.totalCount,
        "reviewCount": props.data.allMysqlComments.totalCount,
        "ratingValue": (props.data.allMysqlComments.nodes.reduce((prev, curr) => prev + curr.rating, 0) / props.data.allMysqlComments.totalCount) % 10
      },
      "review": props.data.allMysqlComments.nodes.slice(0, 10).map((el) => ({
        "@type": "Review",
        "datePublished": new Date(el.created_date).toISOString(),
        "reviewBody": el.text,
        "reviewRating": {
          "@type": "Rating",
          "bestRating": "5",
          "ratingValue": el.rating,
          "worstRating": "1",
        },
        "author": {
          "@type": "Person",
          "name": el.author,
        },
      }))
    }) }}></script>
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": `${siteMetadata.domain}/`,
          "url": `${siteMetadata.domain}/`,
          "name": `Porównywarka kredytów i pożyczek online | ${siteMetadata.title}`,
          "isPartOf": {
            "@id": `${siteMetadata.domain}/#website`
          },
          "about": {
            "@id": `${siteMetadata.domain}/#organization`
          },
          "primaryImageOfPage": {
            "@id": `${siteMetadata.domain}/#primaryimage`
          },
          "image": {
            "@id": `${siteMetadata.domain}/#primaryimage`
          },
          "thumbnailUrl": `${siteMetadata.domain}/assets/Logo.svg`,
          "datePublished": "2014-04-25T18:43:20.000Z",
          "dateModified": props.data.site.buildTime,
          "description": "Porównywarka kredytów i pożyczek online",
          "inLanguage": "pl-PL",
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [
                `${siteMetadata.domain}/`
              ]
            }
          ]
        },
        {
          "@type": "ImageObject",
          "inLanguage": "pl-PL",
          "@id": `${siteMetadata.domain}/#primaryimage`,
          "url": `${siteMetadata.domain}/assets/Logo.svg`,
          "contentUrl": `${siteMetadata.domain}/assets/Logo.svg`,
          "width": 94,
          "height": 35,
          "caption": `${siteMetadata.title} - logo portalu`
        },
        {
          "@type": "WebSite",
          "@id": `${siteMetadata.domain}/#website`,
          "url": `${siteMetadata.domain}/`,
          "name": siteMetadata.title,
          "description": siteMetadata.title,
          "publisher": {
            "@id":  `${siteMetadata.domain}/#organization`
          },
          "inLanguage": "pl-PL"
        },
        {
          "@type": "Organization",
          "@id": `${siteMetadata.domain}/#organization`,
          "name": "Akredo.pl",
          "url": `${siteMetadata.domain}/`,
          "logo": {
            "@type": "ImageObject",
            "inLanguage": "pl-PL",
            "@id": `${siteMetadata.domain}/#/schema/logo/image/`,
            "url": `${siteMetadata.domain}/assets/Logo.svg`,
            "contentUrl": `${siteMetadata.domain}/assets/Logo.svg`,
            "width": 94,
            "height": 35,
            "caption": siteMetadata.title
          },
          "image": {
            "@id": `${siteMetadata.domain}/#/schema/logo/image/`
          },
          "sameAs": [
            "https://www.facebook.com/AkredoPL",
            "https://twitter.com/akredo_pl",
            "https://pl.linkedin.com/company/akredo-sp-z-o-o-",
            "https://www.youtube.com/@AkredoPL"
          ]
        }
      ]
    }) }}></script>
  </BaseHead>
)

const Calculator = () => {
  const inputRef = useRef() 
  const [rangeAmountValue, setRangeAmountValue] = useState(12_000);
  const [numberAmountValue, setNumberAmountValue] = useState(`12000 zł`);
  const formatNumber= (num)=> {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }
  let spanWidth = 113
  let intValue = parseInt(rangeAmountValue, 10);

  if(intValue <=9999){
    spanWidth = 103
  }
  

  return (
    <div className={['home-page-app-box', 'home-calculator',b.p4].join(' ')} style={{ '--min': 1000, '--max': 150_000, '--value': rangeAmountValue }}>
      <div className={[b.dFlex,b.alignItemsCenter].join(' ')}>
        <label htmlFor="amount" className={['blackToWhite','font-size-22'].join(' ')}>
          Weź szybki kredyt z Akredo!        
        </label>
      </div>
      <p className={[b.mb3,'font-size-13','blackToWhite'].join(' ')}>
        Sprawdź jak łatwo można dostać pożyczkę!
      </p>

      <div className={[b.dFlex,b.justifyContentCenter,'blackToWhite',b.mtLg4,b.my3].join(' ')}>
        <input 
          type="text"
          id="amount"
          inputMode="numeric"
          value={numberAmountValue}
          min={1000} 
          max={150_000}
          step={1000}
          onInput={(e) => {
            setNumberAmountValue(Number.parseInt(e.target.value, 10) || 0);
          }}
          onChange={() => {
          // 
          }}
          onBlur={() => {
            let value = Number.parseInt(numberAmountValue, 10);

            if (!Number.isSafeInteger(value) || value < 1000) {
              value = 1000;
            } else if (value > 150_000) {
              value = 150_000;
            } else if (value % 1000) {
              value = value - (value % 1000);
            }

            setNumberAmountValue(`${value} zł`);
            setRangeAmountValue(value);

            inputRef.current.style.setProperty('--value', value);
          }}
          className='styled-number'
        />
      </div>



      <input
        ref={inputRef}
        aria-label="slider"
        value={rangeAmountValue}
        className='styled-slider slider-progress'
        name="amount"
        type="range" 
        min={1000}
        max={150_000} 
        step={1000}
        onChange={() => {
          // 
        }}
        onInput={e => {
          setRangeAmountValue(e.target.value);
          setNumberAmountValue(`${e.target.value} zł`);

          e.target.style.setProperty('--value', e.target.value);
        }} 

      />

      <span style={{ position: 'relative', display: 'block', width: `${spanWidth}px`, textAlign: 'center', fontSize: '15px', }} className='ui-slider-handle'>
        <Icon size={20} icon="Angle-left" className={[b.mr2,'icon-blog'].join(' ')}/>
        {formatNumber(rangeAmountValue)}
        <Icon size={20} icon="Angle-right" className={[b.ml2,'icon-blog'].join(' ')} />
      </span>

      <div className={[b.dFlex,b.justifyContentBetween,'blackToWhite'].join(' ')}>
        <small>1000</small>
        <small>150 000</small>
      </div>
      <a href={`https://out.akredo.pl/wniosek/kredyt?amount=${rangeAmountValue}`} target='_blank' className={[b.btn,'btn-success','btn-normal',b.mtLg4,b.mt3,b.fontWeightNormal].join(' ')}>
        <Icon size={20} color="#FFFFFF" icon="Taking-money" className={b.mr2} />
        Wypełnij wniosek
      </a>
    </div>
  )
}

const IndexPage = ({ 
  data: { allMysqlBlog, homeAdvice, allMysqlComments },
  location: { pathname }
}) => {
  const getConfig = useConfig()

  const isDesktopResolution = useMedia(['(min-width: 992px)'], [true], false);

  return (
    <Layout pathname={pathname}>
      <div className={['intro',b.py0, b.mb4, b.mbLg5].join(' ')}>
        <div className={[b.container,b.pyLg5,b.py3].join(' ')}>
          <div className={[b.row, b.justifyContentBetween].join(' ')}>
            <div className={[b.colLg5,b.mbLg0,b.mb3].join(' ')}>
              <h1 className={[b.mbLg4,b.mb3,'blackToWhite','tp-name'].join(' ')}>Porównywarka kredytów i&nbsp;pożyczek online</h1>
              <div className={[b.mb3,'blueToWhite','font-size-13',b.dFlex].join(' ')}>
                <Icon size={20} icon="Checked-circle" className={b.mr2}/>
                Wnioskuj nawet o 150 000 zł
              </div>
              <div className={[b.mb3,'blueToWhite','font-size-13',b.dFlex].join(' ')}>
                <Icon size={20} icon="Checked-circle" className={[b.mr2,'index-icon'].join(' ')} />
                Rozpatrzymy każdy wniosek bez względu na historię kredytową i zadłużenie
              </div>
              <div className={[b.mb3,'blueToWhite','font-size-13',b.dFlex].join(' ')}>
                <Icon size={20} icon="Checked-circle" className={b.mr2}/>
                Wypełnienie wniosku zajmie Ci do 3 minut
              </div>
              <div className={[b.mb3,'blueToWhite','font-size-13',b.dFlex].join(' ')}>
                <Icon size={20} icon="Checked-circle" className={b.mr2}/>
                Natychmiastowe decyzje i umowy online
              </div>
              {isDesktopResolution&&(
                <>
                  <div className={[b.ptLg4,b.dFlex].join(' ')}>
                    <a href="#rating">
                      <span className={['blueToWhite'].join(' ')}>{getConfig('rating')}</span>
                    </a>
                    <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.ml2].join(' ')}>
                      <div className="index-stars-container">
                        <div className="index-star-box" style={{ width: ` ${getConfig('rating')*20}% ` }}>
                          <Icon size={40} icon="Star-effects" className={b.mr1} />
                          <Icon size={40} icon="Star-effects" className={b.mr1} />
                          <Icon size={40} icon="Star-effects" className={b.mr1} />
                          <Icon size={40} icon="Star-effects" className={b.mr1} />
                          <Icon size={40} icon="Star-effects" />
                        </div>
                        <div className="empty-star-box">
                          <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                          <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                          <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                          <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                          <Icon size={40} icon="Empty-star-filled" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={['blackToWhite',b.w50,'font-size-13',b.mb3].join(' ')}>
                    Na podstawie {allMysqlComments.totalCount} opinii zebranych za pośrednictwem Google 
                  </div>
                </>
              )}
            </div>

            <div className={[b.colLg5, b.colXl4, b.alignSelfCenter].join(' ')}>
              <Calculator />
            </div>
          </div>
        </div>
      </div>

      <section className={[b.container, b.my4, b.myMd5, b.pbLg5].join(' ')}>
        <h2 className="title">Produkty finansowe bez tajemnic</h2>

        <div className={[b.my3, b.colLg6, b.px0].join(' ')}>
          <p className={[b.mt2,'blueToGray', b.mb0].join(' ')}>
            Serwis akredo to kompendium wiedzy o finansach. Znajdziesz tu wszystkie informacje
            pomocne przy zaciąganiu dowolnego kredytu czy pożyczki, porady w kwestiach prawnych,
            podatkowych i zadłużeniowych. Skorzystaj z wiedzy ekspertów przekazanej w artykułach,
            rankingach i świadomie podejmuj decyzje związane z finansami. U nas dowiesz
            się jak postępować w przypadku wystąpienia zadłużenia, jak bezpiecznie zaciągnąć kredyt lub pożyczkę oraz jak poradzić sobie w przypadku kłopotów ze spłatą.
          </p>
        </div>

        <Thumbnail image={homeAdvice} objectFit="contain" className={[b.dNone, b.dLgBlock, b.w100, b.pt3].join(' ')} alt='Plan działania'/>

        <div className={[b.row, b.mt3, b.mtLg0].join(' ')}>
          <div className={[b.colMd6, b.colLg4, b.mtSm4, b.mtLg0].join(' ')}>
            <span className={["finanse-advice-number", b.mb2, b.mbLg4].join(' ')}>01</span>
            
            <h3 className="finanse-advice-title">Analizujemy</h3>

            <p className={[b.mt3,'blueToGray', b.mb0].join(' ')}>Na bieżąco sprawdzamy najlepsze oferty banków i firm pozabankowych! Analizujemy produkty, oceniamy pod kątem wielu czynników, a wyniki przedstawiamy w czytelny sposób - w jednym miejscu porównasz wiele produktów finansowych.</p>
          </div>

          <div className={[b.colMd6, b.colLg4, b.mt3, b.mtSm4, b.mtLg0].join(' ')}>
            <span className={["finanse-advice-number", b.mb2, b.mbLg4].join(' ')}>02</span>
            
            <h3 className="finanse-advice-title">Radzimy</h3>

            <p className={[b.mt3,'blueToGray', b.mb0].join(' ')}>Pomagamy rozwiązać Twoje problemy finansowe z różnych dziedzin związanych z finansami! Wszystkie tematy, przedstawiamy tak aby były łatwe do zrozumienia.</p>
          </div>

          <div className={[b.colMd6, b.colLg4, b.mt3, b.mtSm4, b.mtLg0].join(' ')}>
            <span className={["finanse-advice-number", b.mb2, b.mbLg4].join(' ')}>03</span>
            
            <h3 className="finanse-advice-title">Polecamy</h3>

            <p className={[b.mt3,'blueToGray'].join(' ')}>Regularnie aktualizujemy oferty banków i pożyczkodawców polecając najnowsze promocje i najbardziej opłacalne produkty dostępne na rynku finansowym. Skorzystaj z rankingów oraz zestawień i wybieraj to co najlepsze!</p>
          </div>
        </div>

        <h3 className={['title', b.mt4, b.mtLg5, b.ptLg2, b.mb0].join(' ')}>Akredo pomaga w</h3>
        <div className={['akredo-help', b.row].join(' ')}>
          <div className={[b.colSm6, b.colLg4, b.mt4].join(' ')}>
            <div className="bank-list-products-box">
              <Link to="/zadluzenia/">
                <img src="/assets/bank-images/hammer.png" alt="" loading="lazy" style={{ height: 'auto' }} width="352" height="152" />
                <span className={[b.p3,'blackToWhite','font-size-20','tp-name', b.dBlock].join(' ')}>Zadłużenia</span>
              </Link>
            </div>
          </div>

          <div className={[b.colSm6, b.colLg4, b.mt4].join(' ')}>
            <div className="bank-list-products-box">
              <Link to="/pomoc-socjalna/">
                <img src="/assets/bank-images/human-puzzle.png" alt="" loading="lazy" style={{ height: 'auto' }} width="352" height="152" />
                <span className={[b.p3,'blackToWhite','font-size-20','tp-name', b.dBlock].join(' ')}>Pomoc socjalna</span>
              </Link>
            </div>
          </div>

          <div className={[b.colSm6, b.colLg4, b.mt4].join(' ')}>
            <div className="bank-list-products-box">
              <Link to="/bezpieczenstwo/">
                <img src="/assets/bank-images/shield.png" alt="" loading="lazy" style={{ height: 'auto' }} width="352" height="152" />
                <span className={[b.p3,'blackToWhite','font-size-20','tp-name', b.dBlock].join(' ')}>Bezpieczeństwo</span>
              </Link>
            </div>
          </div>

          <div className={[b.colSm6, b.colLg4, b.mt4].join(' ')}>
            <div className="bank-list-products-box">
              <Link to="/platnosci/">
                <img src="/assets/bank-images/phone.png" alt="" loading="lazy" style={{ height: 'auto' }} width="352" height="152" />
                <span className={[b.p3,'blackToWhite','font-size-20','tp-name', b.dBlock].join(' ')}>Płatności</span>
              </Link>
            </div>
          </div>

          <div className={[b.colSm6, b.colLg4, b.mt4].join(' ')}>
            <div className="bank-list-products-box">
              <Link to="/banki/">
                <img src="/assets/bank-images/money-safe.png" alt="" loading="lazy" style={{ height: 'auto' }} width="352" height="152" />
                <span className={[b.p3,'blackToWhite','font-size-20','tp-name', b.dBlock].join(' ')}>Informacje o bankach</span>
              </Link>
            </div>
          </div>

          <div className={[b.colSm6, b.colLg4, b.mt4].join(' ')}>
            <div className="bank-list-products-box">
              <Link to="/oszczednosci/">
                <img src="/assets/bank-images/money-pig.png" alt="" loading="lazy" style={{ height: 'auto' }} width="352" height="152" />
                <span className={[b.p3,'blackToWhite','font-size-20','tp-name', b.dBlock].join(' ')}>Oszczędności</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      
      <div className={b.container}>
        <div className={['home-page-app-box',b.p3].join(' ')}>
          <div className={[b.colLg12,b.mb4].join(' ')}>
            <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb4].join(' ')}>
              <img src="/assets/big-shield.svg" alt="Tarcza" loading="lazy"  width="199" height="244"/>
            </div>
            <h2 className={[b.textCenter,b.mb4].join(' ')}>Gwarancja bezpieczeństwa Akredo</h2>
            <p className={[b.textCenter,'blackToWhite','fs20', b.pxLg5, b.mxLg5].join(' ')}>
              Korzystając z naszej platformy porównującej produkty finansowe online masz pewność, że Twoje dane są przechowywane zgodnie z najwyższymi standardami bezpieczeństwa.
            </p>
          </div>
          <div className={[b.row,'advice-app-box'].join(' ')}>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite','tp-name'].join(' ')}>Najnowszy i najbezpieczenisjszy certyfikat SSL</div>
              <p className={[b.textCenter,'blueToWhite',b.pt2].join(' ')}>Dzięki temu mamy pewność, że to Ty decydujesz co chcesz zrobić</p>
            </div>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite','tp-name'].join(' ')}>Bezpośrednia współpraca</div>
              <p className={[b.textCenter,b,'blueToWhite',b.pt2].join(' ')}>Z największymi instytucjami finansowymi na całym świecie</p>
            </div>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite','tp-name'].join(' ')}>Międzynarodowe doświadczenie</div>
              <p className={[b.textCenter,'blueToWhite',b.pt2].join(' ')}>Mamy stabilną pozycję w ponad 8 krajach na całym świecie</p>
            </div>
            <div className={[b.colLg3,b.mt2].join(' ')}>
              <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb2].join(' ')}>
                <img src="../assets/shield.svg" alt="tarcza" loading="lazy" width="59" height="79"/>
              </div>
              <div className={[b.textCenter,'font-size-20','blackToWhite','tp-name'].join(' ')}>Szyfrowane dane</div>
              <p className={[b.textCenter,'blueToWhite',b.pt2].join(' ')}>Wszystkie Twoje działania u nas są bezpieczne</p>
            </div>
          </div>
        </div>
      </div>

      <RankingWidget types="all" theme="column" />

      <div className={[b.pb2, b.pbMd4, b.pbLg5].join(' ')}>
        <Team>
          <h2 className="title">{getConfig('home_team_title')}</h2>
          <p className={['team-description', b.my3,'index-team-text'].join(' ')}>
            {getConfig('home_team_description')}
          </p>
          <h3 className={b.mt3} id="organization">Nasz zespół</h3>
          <p className={['team-description', b.my3, b.mbLg5,'index-team-text'].join(' ')}> 
            Akredo tworzą specjaliści różnych branż, którzy wykorzystują swoje doświadczenie, aby dostarczać produkt najlepszej jakości. Cały wygląd serwisu, wszystkie treści serwisu Akredo, a przede wszystkim możliwość uzyskania szybkiej pożyczki gotówkowej to efekt prac zgranego naszego teamu.
          </p>
        </Team>
      </div>

      <Partners />

      <div className="home-page-white-bg">
        <div className="home-page-blue-box">
          <div className={[b.container,'blue-box-px0'].join(' ')}>
            <h2 className={[b.my2,b.mbLg4,'title'].join(' ')}>Napisali o nas</h2>
            <div className={[b.justifyContentBetween,b.row,b.mt2,b.mb3].join(' ')}>
              { allMysqlBlog.nodes.map(({body,mysqlImages,intro,external,mysqlId,image_description},i) => (
                  <div className={[b.colLg3,b.colMd6,b.mt3,b.mtLg0].join(' ')} key={i}>
                    <div className={['home-page-white-box',b.p3].join(' ')}>
                      <div>
                        <div className={[b.p3,b.dFlex,b.alignItemsCenter,b.justifyContentCenter,'dark-mode-image'].join(' ')}>
                        {mysqlId !== 904 ? <a href={external} target="blank" rel="nofollow noopenener noreferrer"><Thumbnail image={mysqlImages[0]}  alt={image_description || ''} /></a> : <Thumbnail image={mysqlImages[0]} 
                        alt={image_description || ''} />}
                        </div>
                        {mysqlId !== 904 ? <a href={external} target="blank" rel="nofollow noopenener noreferrer"><BlogContent content={body} className={[b.my4,b.fontWeightNormal].join(' ')}/></a> : <BlogContent content={body} className={[b.my4,b.fontWeightNormal].join(' ')}/>}
                      </div>
                      <small>{intro}</small>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <HomeComments totalCount={allMysqlComments.totalCount} comments={allMysqlComments.nodes} totalRating={getConfig('rating')} />
      </div>
    </Layout>
  )
}

export default IndexPage

const HomeComments = ({ comments, totalCount, totalRating }) => {
  const basicCountItem = 
    typeof matchMedia === 'function' 
      ? (matchMedia('(min-width: 767px)').matches ? 12 : 5) 
      : 12;

  return (
    <section className={[b.container, b.mt5, b.pb5].join(' ')}>
      <h2 className={['title', b.mb4, b.pbLg3].join(' ')}>Opinie o Akredo</h2>
      
      <div className={[b.row, b.justifyContentCenter, b.justifyContentLgBetween].join(' ')} id="rating">
        <div className={b.colLgAuto}>
          <div className={[b.row, b.alignItemsCenter].join(' ')}>
            <div className={['loando-rating', b.colAuto,'blueToWhite'].join(' ')}>
              {totalRating}
            </div>
            <div className={[b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.ml2].join(' ')}>
              <div className="index-stars-container">
                <div className="index-star-box" style={{ width: ` ${totalRating*20}% ` }}>
                  <Icon size={40} icon="Star-effects" className={b.mr1} />
                  <Icon size={40} icon="Star-effects" className={b.mr1} />
                  <Icon size={40} icon="Star-effects" className={b.mr1} />
                  <Icon size={40} icon="Star-effects" className={b.mr1} />
                  <Icon size={40} icon="Star-effects" />
                </div>
                <div className="empty-star-box">
                  <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                  <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                  <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                  <Icon size={40} icon="Empty-star-filled" className={b.mr1}/>
                  <Icon size={40} icon="Empty-star-filled" />
                </div>
              </div>
            </div>
          </div>

          <p className={[b.mt3, b.mbLg0, 'loando-opinion-description','blueToWhite'].join(' ')}>Na podstawie {totalCount} opinii zebranych za <br className={[b.dNone, b.dLgBlock].join(' ')} /> pośrednictwem Google</p>
        </div>

        <div className={[b.colMd5, b.colLg4, b.colXl3, b.ptLg2].join(' ')}>
          <a href="https://g.page/r/CVbgVwemvcqfEBM/review" target="_blank" rel="noopener noreferrer nofollow" className={[b.btn,"btn-info","btn-cta", b.w100, b.my2, b.mx0].join(" ")}>
            <Icon color="#ffffff" size={20} icon="Pen" className={[b.mr1, "icon-blog"].join(" ")}/>
            <Translate tKey="Add comment"/>
          </a>
        </div>
      </div>

      <Charger list={comments} step={12} countItem={basicCountItem}>
        {({ customList, isLoaded, loadMore }) => (<>
          <div className={[b.row, b.mt3, 'row-blur', b.pbLg5, isLoaded ? 'row-blur-active' : ''].join(' ')}>
            {customList.map(({ author, text, rating, mysqlId, mysqlImage }, i) => (
              <div key={`${mysqlId}-${i}`} className={[b.colMd6, b.colLg4, b.colXl3, b.mt2, b.mtMd4, b.ptLg2].join(' ')}>
                <div className={["product-box", b.h100, b.p3].join(' ')}>
                  <div className={[b.row, b.alignItemsCenter].join(' ')}>
                    <div className={[b.colAuto, b.pr2].join(' ')}>
                      <Thumbnail image={mysqlImage} alt={author} />
                    </div>

                    <div className={['loando-opinion-title', b.col, b.pl1,'blackToWhite'].join(' ')}>
                      <span dangerouslySetInnerHTML={{ __html: author.replace(/\s/, ` <br class="${b.dNone} ${b.dLgBlock}">`) }}></span>
                    </div>
                  </div>

                  <div style={{ paddingLeft: '2px' }} className={[b.mt3, b.mb2, b.pb1, b.dFlex].join(" ")}>
                    {[1, 2, 3, 4, 5].map((el) =>
                      rating >= el ? (
                        <div className="comments-star-box" key={el}>
                          <Icon size={21} icon="Star" className={b.mr1} />
                        </div>
                      ) : (
                        <div className="empty-star-box" key={el}>
                          <Icon size={21} icon="Empty-star" className={b.mr1}/>
                        </div>
                      )
                    )}
                  </div>

                  <p className={[b.mb0, 'loando-opinion-description','blackToWhite'].join(' ')}>{text}</p>
                </div>
              </div>
            ))}
          </div>

          {isLoaded && <button type="button" onClick={loadMore} className={[b.btn,'btn-light','btn-normal','button-256',b.mb5, b.mt4, b.mtLg5,b.mxAuto].join(' ')} aria-label="See more offers">Załaduj więcej opinii</button>}
        </>)}
      </Charger>
    </section>
  );
}

export const query = graphql`
  query IndexQuery {
    site {
      buildTime
    }
    allMysqlSeo(filter: { item_id: { eq: 1 }, item_type: { eq: "firms" }}) {
      nodes {
        ...SeoData
      }
    }
    allMysqlBlog(limit: 4, filter: {term_id: {eq: 86}, external: {nin: ["", null]}}) {
      nodes {
        mysqlImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        body
        intro
        external
        image_description
        mysqlId
      }
    }
    allMysqlComments(filter: { type: { eq: "home" }, item: { eq: 0 }}, limit: 30) {
      totalCount
      nodes {
        mysqlId
        author
        text
        rating
        created_date
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 40, height: 40)
          }
        }
      }
    }
    homeAdvice: file(name: { eq: "home-advice" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
        )
      }
    }
  }
`