import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as b from "./bootstrap.module.css"
import Thumbnail from './thumbnail'
import Translate from './translate'

const Partners = () => {
  const { allFile } = useStaticQuery(graphql`
    query Partners {
      allFile(sort: {fields: name}, filter: {relativeDirectory: {eq: "partners"}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 260
              quality: 50
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  `)
  
  return (
    <section className={[b.py4, b.pyLg5, b.bgWhite, 'partners'].join(' ')}>
      <div className={b.container}>
        <h2 className="title"><Translate tKey="Service partners"/></h2>
        <div className={[b.row, b.mt4,b.ml0].join(' ')}>
          {
            allFile.nodes.map((node, i) => (
              <div key={i} className={['partners-wrapper', b.colMd5,'partners-col', b.mb4, b.dFlex, b.justifyContentCenter, b.alignItemsCenter,'partners-dark'].join(' ')}>
                {node.name === 'clar' ?  
                  <a href="https://www.clar.co/" rel="nofollow noopener" target="_blank">
                    <Thumbnail image={node} className="partners-logo" alt={node.name || ''} />
                  </a> 
                  :
                  <Thumbnail image={node} className="partners-logo"  alt={node.name || ''} /> 
                }
              </div>
            ))
          }
        </div>
      </div>
    </section>
  ) 
}

export default Partners
